import React, { useEffect, useState } from "react"
import QueryString from "query-string"

// import FullscreenVideo from '../FullscreenVideo/FullscreenVideo'
import "./StickyFooter.scss"
import { formatForBigQuery, isMobile } from "../../formatForBigQuery"
// import getApkLinkForUtmParam from '../../lib/get-apk-from-utm-param'
import Onelink from "../../onelink"

type Color = "black" | "white"

interface Props {
  bg: Color
  apkLink?: string
}

const StickyFooter: React.FC<Props> = ({ bg, apkLink }) => {
  const color: Color = bg === "black" ? "white" : "black"
  const boxShadowColor =
    bg === "black" ? "rgba(255, 255, 255, 0.5)" : "rgba(0,0,0,0.3)"
  const buttonBg: Color = bg === "black" ? "white" : "black"

  const src =
    "https://player.vimeo.com/video/475764833?title=0&byline=0&portrait=0&autoplay=1&background=1"
  const [showVideo, setShowVideo] = useState(false)

  const [afSub1, setAfSub1] = useState("")
  useEffect(() => {
    setAfSub1(window.location.href)
  }, [])

  useEffect(() => {
    const footer = document.getElementById("footer-mobile")
    if (footer) {
      footer.style.paddingBottom = "56px"
    }

    return () => {
      if (footer) {
        footer.style.paddingBottom = "0"
      }
    }
  })

  // const [apk, setApk] = useState("https://www.getmega.com/latest-social")
  const [apk, setApk] = useState(Onelink)
  useEffect(() => {
    // setApk(getApkLinkForUtmParam())
    const parsed = QueryString.parse(window.location.search)
    setApk(`${apk}&c=${parsed.name || "Organic"}`)
  }, [])

  return (
    <div className="d-sm-none" id="sticky-btn-container">
      <div
        className="sticky-footer"
        style={{
          // background: bg,
          color,
          boxShadow: `0 0 2px 0 ${boxShadowColor}`,
        }}
      >
        <div className="rating">
          <div>4.7</div>
          <div>Rating</div>
        </div>
        <a
          // href={apkLink ? `${apkLink}?af_sub1=${afSub1}` : apk}
          href={apk}
          style={{
            background: buttonBg,
            color: buttonBg === "black" ? "white" : "black",
          }}
          onClick={() => {
            setShowVideo(true)

            try {
              ;(window as any).analytics?.track("downloadButtonClicked", {
                event_params: formatForBigQuery({
                  device: isMobile() ? "Mobile" : "Desktop",
                  entry_point: "Sticky download button",
                }),
              })
            } catch (er) {
              console.error(er)
            }
          }}
        >
          Download Now
        </a>
        <div className="downloads">
          <div>50,00,000+</div>
          <div>Downloads</div>
        </div>

        {/* {showVideo && (
          <FullscreenVideo
            src={src}
            close={() => {
              setShowVideo(false)
            }}
          />
        )} */}
      </div>
    </div>
  )
}

export default StickyFooter
