import React from "react"
import "./CarromPartyStep.scss"

interface Props {
  stepNumber: number
  stepNumberBg: string
}

const CarromPartyStep: React.FC<Props> = ({
  stepNumber,
  children,
  stepNumberBg,
}) => {
  return (
    <div className="CarromPartyStep">
      <div className="stepNumber" style={{ background: stepNumberBg }}>
        {stepNumber}
      </div>
      <div className="text">{children}</div>
    </div>
  )
}

export default CarromPartyStep
