import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React, { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { LiteYouTubeEmbed } from "react-lite-youtube-embed"
import Sms from "../Sms/Sms"
import "./GetMegaCarromPartyInstagram.scss"
import CarromPartyStep from "./CarromPartyStep"
import { isMobile } from "../../formatForBigQuery"

const Instructuins = () => (
  <div className="ol">
    <CarromPartyStep stepNumber={1} stepNumberBg="#FFCEAC">
      <span className="m">Tap</span> on <span className="m">Download Now</span>{" "}
      to install the GetMega App
    </CarromPartyStep>
    <CarromPartyStep stepNumber={2} stepNumberBg="#FFB6B6">
      Click the <span className="m">Party</span> tab at the bottom. Tap to view
      your <span className="m">GetMega Handle</span>.
    </CarromPartyStep>
    <CarromPartyStep stepNumber={3} stepNumberBg="#AACFCF">
      <span className="m">Share</span> your GetMega Handle & choose Instagram
      Story. <span className="m">Tag</span> your favourite Instagrammer, add{" "}
      <span className="m">#GetMegaCarromParty</span> &{" "}
      <span className="m">post</span> your{" "}
      <span className="m">Instagram Story</span>.
    </CarromPartyStep>
    <CarromPartyStep stepNumber={4} stepNumberBg="#FFB6B6">
      Play games, chill & wait for the Instagrammer to invite you to their game
      on the GetMega App!
    </CarromPartyStep>
  </div>
)

const GetMegaCarromPartyInstagram: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      yt: file(relativePath: { eq: "CarromPartyInstagram.png" }) {
        childImageSharp {
          fluid(maxWidth: 460) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (isMobile()) {
        const clickEvent = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: false,
        })

        const playBtn = document.querySelector(".lty-playbtn")
        playBtn?.dispatchEvent(clickEvent)
      }
    })
  }, [])

  return (
    <div id="GetMegaCarromPartyInstagram">
      {/* <div className="yt-header">
        <Container>
          <Link to="/" className="">
            <Img
              loading="eager"
              fluid={data.logo.childImageSharp.fluid}
              alt="Logo"
              className="getmega-logo"
            />
          </Link>

          <div className="hashtag">#GetMegaCarromParty</div>
        </Container>
      </div> */}

      <div className="mobile d-block d-lg-none">
        {/* <div className="hashtag">#GetMegaCarromParty</div> */}
        <div>
          <Img fluid={data.yt.childImageSharp.fluid} alt="instagram poster" />
        </div>
        <div className="title">
          Play Carrom with your favourite Instagrammer on videochat!
        </div>
        <Instructuins />
      </div>

      <div className="desktop d-none d-lg-block">
        <Container>
          <Row>
            <Col lg={7}>
              <Sms
                title={
                  <>
                    Play Carrom with your
                    <br />
                    favourite Instagrammer
                    <br />
                    on videochat!
                  </>
                }
                description={<Instructuins />}
              />
            </Col>
            <Col>
              <div className="video">
                <Img
                  loading="eager"
                  fluid={data.yt.childImageSharp.fluid}
                  alt=""
                  imgStyle={{ objectFit: "contain" }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="promo-video">
        <Container>
          {/* <div className="embed-container">
            <iframe
              title="video"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/d8ypTJ7WNY8?rel=0&showinfo=0"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div> */}
          <LiteYouTubeEmbed id="d8ypTJ7WNY8" params="rel=0&showinfo=0" />
        </Container>
      </div>
    </div>
  )
}

export default GetMegaCarromPartyInstagram
